
export function rentalFunc() {
  let rentalTop = $('#rentalTop').length;

  if (rentalTop) {
	  let mainSlide01 = $("#mainSlide01").slick({
		autoplay: false,
		arrows: false,
		asNavFor: "#subSlide01",
	  })
	  let subSlide01 = $("#subSlide01").slick({
		slidesToShow: 4,
		asNavFor: "#mainSlide01",
		focusOnSelect: true,
	  })


	  let mainSlide02 = $("#mainSlide02").slick({
		autoplay: false,
		arrows: false,
		asNavFor: "#subSlide02",
	  })
	  let subSlide02 = $("#subSlide02").slick({
		slidesToShow: 4,
		asNavFor: "#mainSlide02",
		focusOnSelect: true,
	  })

	//   $('.tabBtn').click(function () {
	// 	mainSlide01.slick('setPosition');
	// 	subSlide01.slick('setPosition');
	// 	mainSlide02.slick('setPosition');
	// 	subSlide02.slick('setPosition');
	// });

	  $('#tab1').click(function () {
		mainSlide01.slick('setPosition');
	});
	  $('#tab1').click(function () {
		subSlide01.slick('setPosition');
	});
	  $('#tab2').click(function () {
		mainSlide02.slick('setPosition');
	});
	  $('#tab2').click(function () {
		subSlide02.slick('setPosition');
	});


	  $(".accordion dt").click(function(){
		$(this).toggleClass("active");
		$(this).next('dd').slideToggle();
		$('.faq dt').not($(this)).next('dd').slideUp();
		$('.faq dt').not($(this)).removeClass("active");
	});
  }
}