// jQueryプラグイン
import 'jquery.easing';
/* -----------------------------------------------------
ページ内リンクのアニメーション
------------------------------------------------------- */
export function pageLinkAnimationFunc() {
  var scrTop, jsAnkerLogin, moveLogin, position, target, getHeadH;
  scrTop = $('#scrPageTop');
  scrTop.on('click', function () {
    $('body,html').animate({
      scrollTop: 0
    }, 1500, 'easeInOutQuint');
    return false;
  });
}

export function pageMoveAnkerFunc() {
  // var headerHeight,position;
  // $('a[href^="#"]:not(#navContent a[href^="#"]').each(function () {
  //   var pageUrl = location.href.split('#')[0];
  //   if (this.href.indexOf(pageUrl + '#') === 0) {
  //     var id = this.href.split('#')[1];
  //     $(this).on('click', function () {
  //       headerHeight = $('.header').innerHeight();
  //       position = $('#' + id).offset().top - headerHeight;
  //       $('body,html').animate({
  //         scrollTop: position
  //       }, 1000, 'easeInOutQuart');
  //       return false;
  //     });
  //   }
  // });

var urlHash = location.hash;
if (window.matchMedia('(max-width: 767px)').matches) {
  var headerHeight = $('#header').outerHeight();
  if(urlHash) {
      $('body,html').stop().scrollTop(0);
      setTimeout(function(){
          var target = $(urlHash);
          var position = target.offset().top - headerHeight;
          $('body,html').stop().animate({scrollTop:position}, 1000, 'easeInOutQuart');
      }, 100);
  }
} else if (window.matchMedia('(min-width:768px)').matches) {
  var headerHeight = $('.header').outerHeight();
  if(urlHash) {
      $('body,html').stop().scrollTop(0);
      setTimeout(function(){
          var target = $(urlHash);
          var position = target.offset().top - headerHeight;
          $('body,html').stop().animate({scrollTop:position}, 1000, 'easeInOutQuart');
      }, 100);
  }
}
$('a[href^="#"]').click(function() {
    var href= $(this).attr("href");
    var target = $(href);
    var position = target.offset().top - headerHeight;
    $('body,html').stop().animate({scrollTop:position}, 1000, 'easeInOutQuart');
});
}