export function buttonActionFunc() {

	var detailModalFunc = {
		init: function(onLoad) {
		  if(onLoad) this._init();
		},
		_init: function() {
		  // 設定
		  var animateSpeed = 800;
		  var closeElem = '.close a';
		  var btnCloseElem = '.closeBtn';
		  var mainModalContent = '.detailModalContent';
		  var myModalHeight;
		  var pointScrollY;
		  var currentScrollY;
	
		  $(window).scroll(function() {
			pointScrollY = $(window).scrollTop();
		  });
	
		  // スライドされる高さの調整
		   $(window).on('load resize', function() {
			 var windowHeight = $(window).outerHeight();
			 $(mainModalContent).find('.modalInner').css({'height':windowHeight}); //高さを出すスクロールを表示
		   });
	
		  $(mainModalContent).each(function(i) {
			i++;
			// console.log(i);
			$('.modal' + i).on('click touchstart', function() {
			  // 背景固定
			   currentScrollY = $(window).scrollTop();
			  $('body').css({
				position: 'fixed',
				width: '100%',
				top: -1 * currentScrollY,
			  });
	
			  $('#modal' + i).fadeIn(animateSpeed);
	
			  // 上下縦横中央寄せに実行
			  var windowHeight = $(window).outerHeight(); // 縦方向の余白を保つ
			  myModalHeight = $('#modal' + i).outerHeight();
			  if(windowHeight <= myModalHeight) {
				myModalHeight = windowHeight;
				$(mainModalContent).css({'height':'100%'});
			  }
			  centeringModalSyncer(myModalHeight);
			});
	
			function closeAction() {
			  // 背景を固定を解除
			  $('body').attr({style:''});
			  $('html,body').animate({scrollTop:currentScrollY},1);
			  $(mainModalContent).stop().fadeOut(animateSpeed);
			}
	
			// クリックでモーダルをクローズ
			$(closeElem).on('click', function() {
			  closeAction();
			});
		  });
		  $(window).on('resize', centeringModalSyncer);
	
		  // 上下縦横中央寄せの関数
		  function centeringModalSyncer(myModalHeight) {
			$(mainModalContent).css({
			  'top': '0px'
			});
		  }
		}
	  }
	  detailModalFunc.init(true);


	  $("#tab1").click(function(){
		$(".tab2").removeClass("active");
		$("#tab2").removeClass("active");
		$(".tab1").addClass("active");
		$(this).addClass("active");
	  });

	  $("#tab2").click(function(){
		$(".tab1").removeClass("active");
		$("#tab1").removeClass("active");
		$(".tab2").addClass("active");
		$(this).addClass("active");
	});

	$(".faq dt").click(function(){
		$(this).toggleClass("active");
		$(this).next('dd').slideToggle();
		$('.faq dt').not($(this)).next('dd').slideUp();
		$('.faq dt').not($(this)).removeClass("active");
	});
}