//トップページ　キービジュアルスライダー
import slick from 'slick-carousel';
import anime from 'animejs/lib/anime.es.js';

export function idxFunc() {
  let indexPage = $('#indexTop').length;

  if (indexPage) {

    // インスタ埋め込み
    $.ajax({
      type: 'GET',
      url: 'https://graph.facebook.com/v14.0/17841447589894278?fields=name%2Cmedia.limit(9)%7Bcaption%2Clike_count%2Cmedia_url%2Cpermalink%2Ctimestamp%2Cthumbnail_url%2Cmedia_type%2Cusername%7D&access_token=EAARxmjTZAXFkBAHaoSnB1hGkTjZA8ZAQYpAywjZBcldnI5vIYpwMCbF4ZACPDJphs6FGdh3zHdOBgmklo32ZCr2YXIjRa0ZAp8j9lPOMnn0fC7VY4GagoUilCwdrnhLaa73EMTZA2GCWZAaRvXYZCcLMruZCh4VWYI70wTCwPPYgwx8gSqHZBNzZAaUAp',
      dataType: 'json',
      success: function(json) {
               
          var html = '';
          var insta = json.media.data;
          for (var i = 0; i < insta.length; i++) {
          var media_type = insta[i].media_type;
              if ( insta[i].media_type == "IMAGE" || insta[i].media_type == "CAROUSEL_ALBUM" ) {
              html += '<li class="instagramContentList"><a href="' + insta[i].permalink + '" target="_blank" rel="noopener noreferrer"><img src="' + insta[i].media_url + '"></a></li>';                
              } 
              else if (media_type == "VIDEO" ) {
              html += '<li class="instagramContentList"><a href="' + insta[i].permalink + '" target="_blank" rel="noopener noreferrer"><img src="' + insta[i].thumbnail_url + '"></a></li>';           
          var media_type = '';                    
              }       
          }
            $("#instagramContent").append(html);            
      },
      error: function() {
        
        $('#instagramContent').html('<li>読み込みに失敗しました。</li>');
      }
  });

  }
}