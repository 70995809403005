import PerfectScrollbar from 'perfect-scrollbar';

export function headerFunc() {

	const navContent = $("#navContent");
	let scrollPosition;

	$("#menuBox").click(function(){
		navContent.toggleClass("active");
		if(navContent.hasClass("active")){
			$(this).find("span.open").removeClass("active");
			$(this).find("span.close").addClass("active");
			$(this).find(".lineBox p").addClass("active");
			$("#header").addClass("active");
			$("h1").addClass("active");
			scrollPosition = $(window).scrollTop();
			$('body').addClass('fixedBody').css({
				'top': -scrollPosition
			  });
		}else{
			$(this).find("span.open").addClass("active");
			$(this).find("span.close").removeClass("active");
			$(this).find(".lineBox p").removeClass("active");
			$("h1").removeClass("active");
			$("#header").removeClass("active");
			$('body').removeClass('fixedBody').css({
				'top': 0
			  });
			  window.scrollTo(0, scrollPosition);
		}

		$('#navContent a[href^="#"]').click(function(){
			const wait = ms => new Promise(resolve => setTimeout(() => resolve(), ms));
			var headerHeight,position;

			wait()
			.then(() => {
				navContent.removeClass("active");
				$("span.open").addClass("active");
				$("span.close").removeClass("active");
				$(".lineBox p").removeClass("active");
				$("h1").removeClass("active");
				$("#header").removeClass("active");
				return wait(500)
			})
			.then(() => {
				$('body').removeClass('fixedBody').css({
					'top': 0
				  });
				  window.scrollTo(0, scrollPosition);
			})
			.then(() => {
				var id = this.href.split('#')[1];
				headerHeight = $('#header').innerHeight();
				position = $('#' + id).offset().top - headerHeight;
				$('body,html').animate({
				  scrollTop: position
				}, 1000, 'easeInOutQuart');
				return false;
			})
		});
	});

}
